define(['app', 'siteObj', '$location', '$console'], function(app, siteObj, $location, $console) {
  
  var brandsFiltering = function() {

    const arrowKeyDown = 40;
    const arrowKeyUp = 38;

    var self = this;
    self.app = app;
    var _config = {

      viewableListItems: 10,

      viewSelector: '[data-js-element=view]',
      nameSelector: '[data-js-element=brand-name]',
      inputSelector: '[data-js-element=input]',
      brandsListSelector: '[data-js-element=brand-list]',
      ulSelector: '[data-js-element=brands-name-list]',
      liSelector: '[data-js-element=brands-li]',

      activeClass: 'brandsFiltering-active',
      selectedClass: 'brandsFiltering-selected',
      brandsUrl: '/' + siteObj.customerLocale + '/' + siteObj.currencyType + '/brands.nav?useComponent=true'
    };

    var _init = function(element) {
      self.element = element;
      self.view = self.element.querySelector(self.config.viewSelector);
      self.name = self.element.querySelector(self.config.nameSelector);
      self.input = self.element.querySelector(self.config.inputSelector);
      self.brandsList = self.element.querySelector(self.config.brandsListSelector);
      self.brandsUl = self.element.querySelector(self.config.ulSelector);
      self.listItems = self.element.querySelectorAll(self.config.liSelector);
      self.listHeight = 1;
      self.listItemHeight = 20;
      self.searchString = null;
      self.filteredList = [];
      self.selectedIndex = -1;

      self.bind();
      self.getData();

      return self;
    };

    var _successHandler = function(result) {
      self.brandsList.innerHTML = result;
    };

    var _errorHandler = function(error) {
      $console.warn(error, error.message, 'brandsFiltering._init');
    };

    var _getData = function() {
      app.ajax.get({
        url: self.config.brandsUrl,
        dataType: 'JSON',
        success: self.successHandler,
        error: self.errorHandler
      });
    };

    var _removeSelectedClass = function() {
      for (var i = 0, l = self.listItems.length; i < l; i++) {
        app.element.removeClass(self.config.selectedClass, self.listItems[i]);
      }
    };

    var _resetOnMouseWheel = function() {
      /* reset selection if mouse scroll detected */
      self.brandsList.onwheel = self.removeSelectedClass;
    };

    var _resetOnFieldClick = function() {
      /* reset selection if user clicks the input field */
      self.input.onclick = self.removeSelectedClass;
    };

    var _toggleClasses = function() {
      if (!self.searchString || self.searchString.length < 1) {
        for (var i = 0; i < self.listItems.length; i++) {
          app.element.addClass(self.config.activeClass, self.listItems[i]);
        }
      } else {
        for (var i2 = 0; i2 < self.listItems.length; i2++) {
          app.element.removeClass(self.config.activeClass, self.listItems[i2]);
        }
      }
      self.removeSelectedClass();
    };

    var _findMatches = function() {

      var allBrands = self.element.querySelectorAll(self.config.nameSelector);
      var count = 0;
      self.filteredList = [];

      for (var i = 0, l = allBrands.length; i < l; i++) {
        var thisBrand = allBrands[i];
        var brand = thisBrand.innerHTML;
        var result = true;
        if (!self.searchString)
          self.searchString = '';
        if (self.searchString.match(/'/) || self.searchString.match(/-/)) {
          brand = self.regexFilter(thisBrand.innerHTML).substring(0, self.searchString.length);
        } else {
          self.searchString = self.searchString.replace(/'|-/g, '').toLowerCase();
          brand = self.regexFilter(thisBrand.innerHTML).replace(/'|-/g, '').substring(0, self.searchString.length);
        }
        result = brand === self.searchString;
        if (result === true) {
          app.element.addClass(self.config.activeClass, thisBrand.parentElement);
          self.filteredList[count++] = thisBrand.parentElement;
        }
      }
    };

    var _toggleClass = function(thisElement, thisClass) {
      if (app.element.hasClass(thisClass, thisElement)) {
        app.element.removeClass(thisClass, thisElement);
      } else {
        app.element.addClass(thisClass, thisElement);
      }
    };

    var _onEnter = function(selected) {

      if (!selected && self.filteredList.length === 0)
        return;

      var child = null;

      if (selected) {
        child = selected.children[0];
      } else {
        child = self.filteredList[0].children[0];
      }

      var link = child.href;
      var val = child.innerHTML;

      if (val.length !== 0 && link !== 'undefined') {
        self.view.innerHTML = val;
        self.navigateTo(link);
      }
      self.toggleClass(self.element, self.config.activeClass);
    };

    var _navigateTo = function(uri) {
      $location.assign(uri);
    };

    var _onKeyDown = function() {

      self.selectedIndex = self.selectedIndex + 1;
      var scroll_offset = (self.selectedIndex * self.listItemHeight) - (self.listHeight + self.listItemHeight);
      self.brandsUl.scrollTop = scroll_offset + self.listItemHeight;

      app.element.addClass(self.config.selectedClass, self.filteredList[self.selectedIndex]);
    };

    var _onKeyUp = function() {

      self.selectedIndex = self.selectedIndex - 1;
      var scroll_offset = (self.selectedIndex * self.listItemHeight) - (self.listHeight + self.listItemHeight);
      self.brandsUl.scrollTop = scroll_offset - self.listItemHeight;

      app.element.addClass(self.config.selectedClass, self.filteredList[self.selectedIndex]);
    };

    var _onArrowKeys = function(key, selected) {
      if (key === arrowKeyDown && self.selectedIndex < self.filteredList.length - 1) {
        self.onKeyDown();
      } else if (key === arrowKeyUp && self.selectedIndex > 0) {
        self.onKeyUp();
      } else {
        app.element.addClass(self.config.selectedClass, selected);
      }
    };

    var _keyDown = function() {
      self.input.onkeydown = function(pressedKey) {
        return pressedKey.which !== arrowKeyUp;
      };
    };

    var _keyUp = function() {
      self.input.onkeyup = function(releasedKey) {

        var key = releasedKey.keyCode || releasedKey.which;
        var selected = null;

        self.searchString = (self.input.value).replace(/\s/g, ''); // Remove spaces
        for (var i = 0, l = self.listItems.length; i < l; i++) {
          if (app.element.hasClass(self.config.selectedClass, self.listItems[i])) {
            selected = self.listItems[i];
          }
        }

        self.toggleClasses();
        self.findMatches();

        self.resetOnMouseWheel();
        self.resetOnFieldClick();

        if (key === 13) {
          self.onEnter(selected);
        }

        if (key !== arrowKeyDown && key !== arrowKeyUp) {
          self.selectedIndex = -1;
          return;
        }

        self.removeSelectedClass();
        self.onArrowKeys(key, selected);
      };
    };

    var _recalculateHeight = function() {
      self.brandsUl.style.maxHeight = self.listItemHeight * self.config.viewableListItems + 'px';
    };

    var _resetSearchOnSelectedBrand = function() {
      Array.from(self.filteredList).filter(function(element) {
        return app.element.hasClass(self.config.selectedClass, element);
      }).map(function(element) {
        return app.element.removeClass(self.config.selectedClass, element);
      });
      self.input.value = '';
      self.brandsUl.scrollTop = 0;
      self.input.focus();
    };

    var _redirectIfEmpty = function() {
      if (!(self.brandsUl.children).length) {
        if (siteObj.props.deferComponents.brands) {
          self.navigateTo(siteObj.props.deferComponents.brands);
        }
      }
    };

    var _viewClickCallback = function() {

      self.brandsUl = self.element.querySelector(self.config.ulSelector);

      self.redirectIfEmpty();

      self.listItems = self.element.querySelectorAll(self.config.liSelector);

      self.toggleClass(self.element, self.config.activeClass);
      for (var i = 0, l = self.listItems.length; i < l; i++) {
        app.element.addClass(self.config.activeClass, self.listItems[i]);
      }

      self.recalculateHeight();
      self.resetSearchOnSelectedBrand();
    };

    var _regexFilter = function(selector) {
      return selector.replace(/é/g, 'e').replace(/\s+|\s+/g, '').replace(/\b\s/g, '').replace(/\s/g, '').toLowerCase();
    };

    var _onKeyEvents = function() {
      self.keyDown();
      self.keyUp();
    };

    var _onClickView = function() {
      self.view.onclick = self.viewClickCallback;
    };

    var _bind = function() {
      self.onClickView();
      self.onKeyEvents();
    };

    self.init = _init;
    self.bind = _bind;
    self.keyUp = _keyUp;
    self.config = _config;
    self.getData = _getData;
    self.keyDown = _keyDown;
    self.onEnter = _onEnter;
    self.onKeyUp = _onKeyUp;
    self.onKeyDown = _onKeyDown;
    self.navigateTo = _navigateTo;
    self.toggleClass = _toggleClass;
    self.onArrowKeys = _onArrowKeys;
    self.findMatches = _findMatches;
    self.onClickView = _onClickView;
    self.onKeyEvents = _onKeyEvents;
    self.regexFilter = _regexFilter;
    self.errorHandler = _errorHandler;
    self.toggleClasses = _toggleClasses;
    self.successHandler = _successHandler;
    self.redirectIfEmpty = _redirectIfEmpty;
    self.recalculateHeight = _recalculateHeight;
    self.resetOnMouseWheel = _resetOnMouseWheel;
    self.resetOnFieldClick = _resetOnFieldClick;
    self.viewClickCallback = _viewClickCallback;
    self.removeSelectedClass = _removeSelectedClass;
    self.resetSearchOnSelectedBrand = _resetSearchOnSelectedBrand;
  };

  return brandsFiltering;
});
